<template>
    <div class="layout-content">
        <Toast position="bottom-right"/>
        <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>
        <router-view></router-view>
    </div>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';

export default {
    name: 'App',
    components: {
        ConfirmDialog
    }
}
</script>

<style lang="scss">
@import './styles/app/layout.scss';
</style>
