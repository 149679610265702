import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import AuthService from "@/services/auth.service";

const routes = [
    {
        path: '',
        component: () => import('@/views/Login'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login'),
    },
    {
        path: '/forgotten-password',
        name: 'forgotten_password',
        component: () => import('@/views/ForgottenPassword'),
    },
    {
        path: '',
        name: 'Kezdőoldal',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Vezérlőpult',
                component: () => import('@/views/Dashboard.vue')
            },
            {
                path: '/profile',
                name: 'Profilom',
                component: () => import('@/views/Profile.vue')
            },
            {
                path: '/tickets',
                name: 'tickets',
                component: () => import('@/views/Tickets/Tickets.vue')
            },
            {
                path: '/ticket/new',
                name: 'ticket_new',
                component: () => import('@/views/Tickets/new.vue')
            },
            {
                path: '/ticket/:id',
                name: 'ticket_edit',
                component: () => import('@/views/Tickets/edit.vue')
            },
            {
                path: '/orders',
                name: 'Vásárlások',
                component: () => import('@/views/Orders/Orders.vue')
            },
            {
                path: '/orders/:id',
                name: 'orders_show',
                component: () => import('@/views/Orders/show.vue')
            },
            {
                path: '/aszf',
                name: 'aszf',
                component: () => import('@/views/ASZF/ASZF.vue')
            },
            {
                path: '/aszf/new',
                name: 'aszf_new',
                component: () => import('@/views/ASZF/new.vue')
            },
            {
                path: '/aszf/:id',
                name: 'aszf_edit',
                component: () => import('@/views/ASZF/edit.vue')
            },
            {
                path: '/environment-protection',
                name: 'environment_protection',
                component: () => import('@/views/EnvironmentalProtection/EnvironmentalProtection.vue')
            },
            {
                path: '/environment-protection/new',
                name: 'environment_protection_new',
                component: () => import('@/views/EnvironmentalProtection/new.vue')
            },
            {
                path: '/environment-protection/:id',
                name: 'environment_protection_edit',
                component: () => import('@/views/EnvironmentalProtection/edit.vue')
            },
            {
                path: '/gdpr',
                name: 'gdpr',
                component: () => import('@/views/GDPR/GDPR.vue')
            },
            {
                path: '/gdpr/new',
                name: 'gdpr_new',
                component: () => import('@/views/GDPR/new.vue')
            },
            {
                path: '/gdpr/:id',
                name: 'gdpr_edit',
                component: () => import('@/views/GDPR/edit.vue')
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('@/views/News/News.vue')
            },
            {
                path: '/news/:id',
                name: 'news_show',
                component: () => import('@/views/News/show.vue')
            },
            {
                path: '/game-categories',
                name: 'game_categories',
                component: () => import('@/views/Games/GameCategory/GameCategories.vue')
            },
            {
                path: '/game-categories/new',
                name: 'game_categories_new',
                component: () => import('@/views/Games/GameCategory/new.vue')
            },
            {
                path: '/game-categories/:id',
                name: 'game_categories_edit',
                component: () => import('@/views/Games/GameCategory/edit.vue')
            },
            {
                path: '/game-properties',
                name: 'game_properties',
                component: () => import('@/views/Games/GameProperty/GameProperties.vue')
            },
            {
                path: '/game-properties/new',
                name: 'game_properties_new',
                component: () => import('@/views/Games/GameProperty/new.vue')
            },
            {
                path: '/game-properties/:id',
                name: 'game_properties_edit',
                component: () => import('@/views/Games/GameProperty/edit.vue')
            },
            {
                path: '/games',
                name: 'games',
                component: () => import('@/views/Games/Games.vue')
            },
            {
                path: '/games/new',
                name: 'games_new',
                component: () => import('@/views/Games/new.vue')
            },
            {
                path: '/games/:id',
                name: 'games_edit',
                component: () => import('@/views/Games/edit.vue')
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/Users/Users.vue')
            },
            {
                path: '/user/new',
                name: 'user_new',
                component: () => import('@/views/Users/new.vue')
            },
            {
                path: '/users/:id',
                name: 'users_show',
                component: () => import('@/views/Users/show.vue')
            },
            {
                path: '/treasure-hunts',
                name: 'treasure_hunts',
                component: () => import('@/views/TreasureHunts/TreasureHunts.vue')
            },
            {
                path: '/treasure-hunt/new',
                name: 'treasure_hunt_new',
                component: () => import('@/views/TreasureHunts/new.vue')
            },
            {
                path: '/treasure-hunt/:id',
                name: 'treasure_hunt_edit',
                component: () => import('@/views/TreasureHunts/edit.vue')
            },
            {
                path: '/questions',
                name: 'questions',
                component: () => import('@/views/TreasureHunts/Questions/Questions.vue')
            },
            {
                path: '/question/new',
                name: 'question_new',
                component: () => import('@/views/TreasureHunts/Questions/new.vue')
            },
            {
                path: '/question/:id',
                name: 'question_edit',
                component: () => import('@/views/TreasureHunts/Questions/edit.vue')
            },
            {
                path: '/answers',
                name: 'answers',
                component: () => import('@/views/TreasureHunts/Answers/Answers.vue')
            },
            {
                path: '/answer/new',
                name: 'answer_new',
                component: () => import('@/views/TreasureHunts/Answers/new.vue')
            },
            {
                path: '/answer/:id',
                name: 'answer_edit',
                component: () => import('@/views/TreasureHunts/Answers/edit.vue')
            },
            {
                path: '/push-notification',
                name: 'push_notification',
                component: () => import('@/views/PushNotification/new.vue')
            },
            // {
            //     path: '/:catchAll(.*)*',
            //     name: '404',
            //     component: Page404
            // }
        ],
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem('user');
    const expire = localStorage.getItem('expire');

    if ((!user || Date.now() >= new Date(expire)) && (to.fullPath !== '/')) {
        if (to.fullPath === '/forgotten-password') {
            next();
        } else {
            AuthService.logout();
            next('/');
        }
    } else {
        next();
    }
});

export default router
