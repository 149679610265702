<template>
    <div class="surface-card shadow-2 py-3 px-5 mb-5 flex relative lg:static">
        <div class="flex-grow-1 hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 lg:border-top-none">
                <li @click="this.$router.push('/profile')">
                    <a v-ripple class="flex align-items-center cursor-pointer pull-right p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                        <i class="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block font-medium pl-3">{{ this.user.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Header',
    components: {},
    setup() {
        return {
            user: {}
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem('userData'));
    }
}
</script>
