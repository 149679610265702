<template>
    <div id="app-sidebar" class="surface-section h-full lg:h-auto hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none" style="width:280px">
        <div class="flex flex-column h-full">
            <!--                <div class="flex align-items-center px-5 flex-shrink-0" style="height:60px">-->
            <!--                    <img src="images/blocks/logos/hyper-700.svg" alt="Image" height="30">-->
            <!--                </div>-->
            <div class="overflow-y-auto">
                <ul class="list-none p-3 m-0">
                    <li>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a v-ripple @click="this.$router.push('/')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-home mr-2"></i>
                                    <span class="font-medium">Kezdőlap</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple @click="this.$router.push('/tickets')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-ticket mr-2"></i>
                                    <span class="font-medium">Jegyek</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple @click="this.$router.push('/orders')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-shopping-cart mr-2"></i>
                                    <span class="font-medium">Vásárlások</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple @click="this.$router.push('/news')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-list mr-2"></i>
                                    <span class="font-medium">Wordpress hírek</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                                   v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                                    <i class="pi pi-align-center mr-2"></i>
                                    <span class="font-medium">Tartalomkezelés</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <a v-ripple @click="this.$router.push('/aszf')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">ÁSZF</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a v-ripple @click="this.$router.push('/gdpr')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">GDPR</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                                   v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                                    <i class="pi pi-compass  mr-2"></i>
                                    <span class="font-medium">Kincsvadászat</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <a v-ripple @click="this.$router.push('/questions')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">Kérdések</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a v-ripple @click="this.$router.push('/answers')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">Válaszok</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a v-ripple @click="this.$router.push('/treasure-hunts')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">Kincsvadászat</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a v-ripple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                                   v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                                    <i class="pi pi-star  mr-2"></i>
                                    <span class="font-medium">Játékok</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <a v-ripple @click="this.$router.push('/game-categories')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">Játék kategóriák</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a v-ripple @click="this.$router.push('/game-properties')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">Játék tulajdonságok</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a v-ripple @click="this.$router.push('/games')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <span class="font-medium">Játékok</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a v-ripple @click="this.$router.push('/users')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-users mr-2"></i>
                                    <span class="font-medium">Felhasználók</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple @click="this.$router.push('/push-notification')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-envelope mr-2"></i>
                                    <span class="font-medium">Azonnali értesítés</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple @click="this.$router.push('/environment-protection')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-globe mr-2"></i>
                                    <span class="font-medium">Környezetvédelem</span>
                                </a>
                            </li>
<!--                            <li>-->
<!--                                <a v-ripple @click="this.$router.push('/settings')" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">-->
<!--                                    <i class="pi pi-cog mr-2"></i>-->
<!--                                    <span class="font-medium">Beállítások</span>-->
<!--                                </a>-->
<!--                            </li>-->
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="mt-auto">
                <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
                <a v-ripple class="m-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple" @click="logOut">
                    <span class="font-medium">Kijelentkezés</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SidebarComponent",
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>

</style>