import axios from 'axios';
import authHeader from "@/services/auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'admin-login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data.token));
                    localStorage.setItem('expire', response.data.expire);

                    this.getData().then(
                        (data) => {
                            localStorage.setItem('userData', JSON.stringify(data.data));
                        }
                    )
                }

                return response.data.token;
            });
    }

    getData() {
        return axios.get(API_URL + 'user-data', { headers: authHeader() });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('expire');
        window.location.href = "/";
    }
}

export default new AuthService();
