<template>
    <Header />
    <div class="min-h-screen flex relative lg:static surface-ground">
        <Sidebar></Sidebar>
        <div class="min-h-screen flex flex-column relative flex-auto">
            <div class="flex flex-column flex-auto">
                <div class="pl-5">
                    <div class="grid">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";

export default {
    name: 'DefaultLayout',
    components: {
        Sidebar,
        Header
    },
}
</script>
